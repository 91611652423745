// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
  padding-top: 10px !important;
  padding-left: 16px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 0.7rem !important;
  justify-content: center !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/App/EventServicesScreens/SelectServicesType/style.scss"],"names":[],"mappings":"AAAA;EAEI,4BAAA;EACA,6BAAA;EACA,wBAAA;EACA,iCAAA;EACA,sBAAA;EACA,kCAAA;AAAJ","sourcesContent":[".list-container {\n    // padding: 10px 10px !important;\n    padding-top: 10px !important; \n    padding-left: 16px !important;\n    display: flex !important;\n    flex-direction: column !important;\n    gap: 0.7rem !important;\n    justify-content: center !important;\n    // margin-left: 0.8rem !important;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
