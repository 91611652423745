import React, { useState, useEffect } from 'react';
import {
    Container, Grid, IconButton, Card, Box, Divider, CardContent,
    Typography, TextField, MenuItem, Checkbox, Button, FormControlLabel,
    styled
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MOBILE_URL } from '../../../../constants/endUrl/mobileCode';
import { LOGIN_BIZZ_URL, VERIFY_BIZZ_URL } from '../../../../constants/endUrl/login';

const CustomHeading = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '190px',
    fontFamily: 'Open Sans, sans-serif',
});

const Index = () => {
    const [formData, setFormData] = useState({
        dropdownValue: 'supplier',
        mobileNumber: '',
        agreeToTerms: false,
        countryCode: '+44', // Default to UK code
        countryName: 'United Kingdom', // Default country name
    });
    const [showCheckboxError, setShowCheckboxError] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch country codes from the API
        axios.get(`${process.env.REACT_APP_API_URL}${MOBILE_URL}`)
            .then((res) => {
                if (res.status === 200 && res.data.body && res.data.body.data) {
                    // Map response data to required structure
                    const fetchedCountryCodes = res.data.body.data.map((country) => ({
                        code: country.telephonePrefix,
                        label: country.countryName,
                    }));
                    setCountryCodes(fetchedCountryCodes);
                }
            })
            .catch((error) => console.error('Error fetching country codes:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // If countryCode is selected, also set countryName based on the selected country code
        if (name === 'countryCode') {
            const selectedCountry = countryCodes.find(country => country.code === value);
            setFormData({ 
                ...formData, 
                countryCode: value, 
                countryName: selectedCountry ? selectedCountry.label : '' 
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, agreeToTerms: e.target.checked });
        if (showCheckboxError && e.target.checked) {
            setShowCheckboxError(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.agreeToTerms) {
            setShowCheckboxError(true);
            return;
        }

        const endpoint = formData.dropdownValue === 'customer'
            ? `${process.env.REACT_APP_API_URL}/${LOGIN_BIZZ_URL}`
            : `${process.env.REACT_APP_API_URL}/${VERIFY_BIZZ_URL}`;

        axios.post(endpoint, {
            mobileNo: formData.mobileNumber,
            country: formData.countryName,
            countryDialCode: formData.countryCode,
        })
            .then((res) => {
                if (res.status === 200) {
                    // console.log();
                    if(res.data.body.userId){
                        navigate('/verify', { state: { formData } });
                    }
                    else {
                        toast.error('User Does Not Exist!',);
                        // alert(``)
                    }
                }
            })
            .catch((error) => console.error("Error deleting account:", error));

         // Pass formData as state
        console.log('Form submitted:', formData);
    };

    return (
        <Container maxWidth="md" sx={{ maxWidth: '720px', padding: '1rem', border: '1px solid #F2F2F2', marginBottom: '3rem' }}>
            <Grid>
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', boxShadow: 'none' }}>
                    <Box>
                        <CardContent>
                            <CustomHeading variant="h6" align="center" gutterBottom>
                                Delete Account Request
                            </CustomHeading>
                            {/* <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
                                Please fill out the form below
                            </Typography> */}
                        </CardContent>
                        <IconButton
                            onClick={() => navigate(-1)}
                            sx={{
                                position: 'absolute', top: '10px', left: '10px', borderRadius: '50%', width: '40px', height: '40px',
                                backgroundColor: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                '&:hover': { backgroundColor: '#E5E5E5' },
                            }}
                        >
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                    </Box>
                </Card>
            </Grid>
            <Divider variant="fullWidth" orientation="horizontal" sx={{ my: 2 }} />
            <Box component="form" onSubmit={handleSubmit} sx={{ padding: '0 2rem' }}>
                <Typography variant="body1" align="left" style={{ marginTop: '2rem', color: '#949494',fontSize: '1rem' , fontFamily: 'Open Sans, sans-serif' }}>
                    User Type
                </Typography>
                <TextField
                    fullWidth
                    select
                    name="dropdownValue"
                    value={formData.dropdownValue}
                    required
                    onChange={handleInputChange}
                    variant="outlined"
                    sx={{ "& fieldset": { border: 'none' } }}
                    InputProps={{
                        style: {
                          borderRadius: '10px',
                          backgroundColor: '#f2f2f2',
                          marginTop: '.3rem',
                          height: '47px',
                          fontSize: '1rem',
                          fontFamily: 'Open Sans, sans-serif'
                        },
                    }}
                >
                    <MenuItem value="customer">Customer</MenuItem>
                    <MenuItem value="supplier">Supplier</MenuItem>
                </TextField>

                
                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={4}>
                    <Typography variant="body1" align="left" style={{ marginTop: '1rem', color: '#949494', fontFamily: 'Open Sans, sans-serif' }}>
                    Country Code
                    </Typography>
                        <TextField
                            fullWidth
                            select
                            // label="Country Code"
                            name="countryCode"
                            value={formData.countryCode}
                            onChange={handleInputChange}
                            variant="outlined"
                            required
                            sx={{ "& fieldset": { border: 'none' } }}
                            InputProps={{
                                style: {
                                borderRadius: '10px',
                                backgroundColor: '#f2f2f2',
                                marginTop: '.5rem',
                                fontSize: '1rem',
                                height: '47px',
                                fontFamily: 'Open Sans, sans-serif'
                                },
                            }}
                        >
                            {countryCodes.map((country) => (
                                <MenuItem key={country.code} value={country.code}>
                                    {country.label} ({country.code})
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    
                    <Grid item xs={8}>
                    <Typography variant="body1" align="left" style={{ marginTop: '1rem', color: '#949494', fontFamily: 'Open Sans, sans-serif' }}>
                        Mobile Number*
                        </Typography>
                        <TextField
                            fullWidth
                            required
                            sx={{ "& fieldset": { border: 'none' } }}
                            InputProps={{
                                style: {
                                  borderRadius: '10px',
                                  backgroundColor: '#f2f2f2',
                                  marginTop: '0.5rem',
                                  height: '47px',
                                  fontFamily: 'Open Sans, sans-serif'
                                },
                            }}
                            // label="Mobile Number"
                            name="mobileNumber"
                            value={formData.mobileNumber }
                            onChange={handleInputChange}
                            variant="outlined"
                            // margin="normal"
                            type="tel"
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                </Grid>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.agreeToTerms}
                            onChange={handleCheckboxChange}
                            name="agreeToTerms"
                            required
                        />
                    }
                    label="By deleting this account you agree to Events6 Terms & Conditions. Once the account deletion process is initiated, it cannot be reversed, and you will no longer have access to your account or associated features"
                    sx={{ marginY: 2 }}
                />
                {showCheckboxError && (
                    <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
                        Please click on the checkbox to continue.
                    </Typography>
                )}

                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Continue
                </Button>
            </Box>
        </Container>
    );
};

export default Index;
