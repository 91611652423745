
const packagesServicesEvent = {
    getEventSettings: () => '/app/settings/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    getEventReligion: () => '/data/religions/search/1/500/sortOrder.ASC',
    getEventTypes: (eventTypeCount) => `/data/event-types/search/1/${eventTypeCount}/sortOrder.ASC`,
    getCommunityTypes: () => '/data/communities/search/1/500/sortOrder.ASC',
    getEventPackagesSearch: (packagesCardCount) => `/app/event-packages/search/1/${packagesCardCount}/sortOrder.ASC`,
    getEventPackagesServices: (packageId) => `/app/event-packages/${packageId}/services`,
    //  new
    getEventPackagesDetails: (packageId) => `/app/event-packages/${packageId}`,
    // new
    getEventServices: (serviceId) => `/app/event-services/${serviceId}`,
    getPackagesSearchKeyword: () => '/data/keywords/search'
}
export default packagesServicesEvent