import Home from './Home'
import EventLocation from './PackagesServicesScreens/EventLocation'
import EventType from './PackagesServicesScreens/EventType'
import EventGuests from './PackagesServicesScreens/EventGuests'
import EventBudget from './PackagesServicesScreens/EventBudget'
import PackagesScreen from './PackagesServicesScreens/PackagesScreen'
import PackagesDetails from './PackagesServicesScreens/PackagesDetails'
import PackageServicesIncludes from './PackagesServicesScreens/PackageServicesIncludes'
import SelectServices from './EventServicesScreens/SelectServicesType'
import ServiceBudget from './EventServicesScreens/ServiceBudget'
import DynamicEventServiceScreen from './EventServicesScreens/DynamicEventServiceScreen'
import EventServicesIncludes from './EventServicesScreens/EventServicesIncludes'
import BrandPackagesService from './CommonScreens/BrandPackagesService'
import DeleteScreen from './CommonScreens/DeleteScreen'
import Success from './CommonScreens/Success'

const Screens = {
    Home,
    EventLocation,
    EventType,
    EventGuests,
    EventBudget,
    PackagesScreen,
    PackagesDetails,
    PackageServicesIncludes,
    SelectServices,
    ServiceBudget,
    DynamicEventServiceScreen,
    EventServicesIncludes,
    BrandPackagesService,
    DeleteScreen, 
    Success
}
export default Screens