export const routePaths = {
    home:'/home',
    eventLocation:'/event/location',
    eventType:'/event/type',
    eventGuests:'/event/guests',
    eventBudget: '/event/budget',
    login:'/login',
    registration:'/register',
    deleteScreen:'/delete-account',
    verification:'/verification',
    packages:'/event/packages',
    packagesDetails:'/event/packages/details/:packageId/:uuid',
    packageServicesIncludes:'/event/services/view/:serviceId',
    servicesSelect:'/services/select',
    servicesBudget:'/services/budget',
    dynamicEventServiceRoute:'/services/dynamic/services',
    eventServicesIncludesRoute:'/services/view/:serviceId/:uuid',
    brandPackagesService:'/brands/packages/service/:accountId',
    verify: '/verify',
    success: '/success'
}