import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router';

const DeletionSuccess = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/'); // Adjust this route to where you'd like users to be directed
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '2rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2rem',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          backgroundColor: '#ffffff',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Deletion Request Sent Successfully
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem', color: 'textSecondary' }}>
          Your request to delete the account has been submitted successfully. You will no longer have access to your account after the process is completed.
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '2rem', color: 'textSecondary' }}>
          If you have any questions, please contact our support team.
        </Typography>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleBackToHome}
          sx={{
            padding: '0.5rem 2rem',
            borderRadius: '25px',
          }}
        >
          Back to Home
        </Button> */}
      </Box>
    </Container>
  );
};

export default DeletionSuccess;
