import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HeaderLogo from "../../assets/images/eventHeaderLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";

import ios_logo from "../../assets/images/appstore.png";
import android_logo from "../../assets/images/playstore.png";
import { Margin } from "@mui/icons-material";

const Index = () => {
  const navigate = useNavigate();
  return (
    <AppBar
      position="static"
      sx={{
        border: "1px solid #F2F2F2",
        boxShadow: "none",
        backgroundColor: "#FFFFFF",
        position: "relative",
        
      }}
    >
      <Grid xs={12} justifyContent="center" alignItems="center" position='fixed' top='0' width= "100%" zIndex= '9999' backgroundColor = '#d8d5d5'>
       <Container
       maxWidth="md">

        {/* <Grid
          style={{
            display: "flex",
            textAlign: "center",
            width: '100%',
            
          }}
        >
             <Typography
          style={{
            marginTop: "10px",
            marginBottom: "1rem",
            cursor: "pointer",
            textAlign: "left",
            width: "50%",
          }}
          variant="h6"
        >
          Download Events6 App
          
        </Typography>
        <Grid
        style={{
            marginTop: "10px",
            direction: "right",
            textAlign: "right",
            width: '50%',
            
          }}
        >
        <Box
            onClick={(e) => {
              console.log("ios click");
            }}
            component="img"
            src={ios_logo} // Update URL with 1000px width
            style={{
              width: "100px",
              marginBottom: "0rem",
              cursor: "pointer",
              alignItems: "right",
              marginLeft: "30px",
              
            }}
          />
          <Box
            onClick={(e) => {
              console.log("android click");
            }}
            component="img"
            src={android_logo} // Update URL with 1000px width
            style={{
              width: "100px",
              marginTop: "0rem",
              marginBottom: "0rem",
              cursor: "pointer",
              marginLeft: "10px"
            }}
          />
        </Grid>
        </Grid>
        */}
        </Container>
      </Grid>
      <Toolbar sx={{ display: "flex", justifyContent: "center", marginTop: "80px" }}>
        <Typography variant="h6" component="div" justifyContent='center'>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            <Box
              component="img"
              sx={{ height: "49px", width: "139px", marginTop: "7px" }}
              src={HeaderLogo}
            ></Box>
            
          </Link>
          
        </Typography>
        
      </Toolbar>
      {
 
  !["/delete-account", "/verify", "/success"].includes(window.location.pathname) && (
    <Typography sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        style={{
          marginTop: "10px",
          direction: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
          margin: "0 auto"
        }}
      >
        <Typography variant="h6">Download the Events6 App</Typography>
        <Box
          onClick={(e) => {
            window.location.href = process.env.REACT_APP_DOWNLOAD_IOS_APP;
          }}
          component="img"
          src={ios_logo} // Update URL with 1000px width
          style={{
            width: "150px",
            marginTop: "5px",
            cursor: "pointer",
            alignItems: "center"
          }}
        />
        <Box
          onClick={(e) => {
            window.location.href = process.env.REACT_APP_DOWNLOAD_ANDROID_APP;
          }}
          component="img"
          src={android_logo} // Update URL with 1000px width
          style={{
            width: "150px",
            marginTop: "0rem",
            marginBottom: "0rem",
            cursor: "pointer",
            marginLeft: "10px"
          }}
        />
      </Grid>
    </Typography>
  )


}

    </AppBar>
  );
};

export default Index;
