import React from 'react'
// import SideBar from '../components/common/Sidebar/SideBar';
import { Navigate } from 'react-router';
import BottomBar from '../components/BottomBar'
import { Box } from '@mui/material';

const AuthLayout = (props) => {
    if (!localStorage.getItem("access_token")) {
        return (
            <Navigate to={{ pathname: "/login", }} />
        );
    }
    return (
        <React.Fragment>
            <Box sx={{ border: '1px solid #F2F2F2' ,  minHeight: '555px' }}>
                {/* <SideBar /> */}
                <Box className="main-content">{props.children}</Box>
                <BottomBar />
            </Box>
        </React.Fragment>
    )
}

export default AuthLayout