import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, IconButton } from '@mui/material';
import { LoginStyles } from './style'
import { toast } from 'react-toastify';
import { routePaths } from '../../../routes/routePaths'
import { useNavigate } from 'react-router';
import { loginUser } from '../../../redux/auth/action'
import { useDispatch } from 'react-redux';
import CountryCodeConstants from '../../../constants/CountryCodeConstants/CountryCodeConstants.json'
import ModalPopUp from "../../../components/ModalPopUp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Index = () => {
  const [loginInputs, setLoginInputs] = useState({ defaultCountryCode: '', number: "" })
  const [error, setError] = useState(false);
  const [countyInputValue, setCountyInputValue] = useState({ name: 'United Kingdom', flag: '🇬🇧', code: 'GB', dial_code: '+44' })
  const [openPopUp, setOpenPopUp] = useState(false);
  const [filteredCountryCode, setFilteredCountryCode] = useState([]);
  const [searchTerm, setSearchTerm] = useState()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleMobileNumberChange = (event) => {
    const inputNumber = event.target.value;
    const numericRegex = /^[0-9\b]+$/;
    if (numericRegex.test(inputNumber) || inputNumber === '') {
      setError(false);
      setLoginInputs({ ...loginInputs, number: inputNumber });
    } else {
      setError(true);
    }
  };
  const handleContinueClick = () => {
    if (!countyInputValue.name || !loginInputs.number) {
      toast.error("Please enter both country and mobile number");
    } else {
      let payload = {
        "country": countyInputValue.name,
        "countryDialCode": `${countyInputValue.dial_code}`,
        "adminContactNo": `${loginInputs.number}`
      }
      dispatch(loginUser(payload, navigate))
    }
  };

  const handleCloseOpenModal = () => {
    setOpenPopUp(!openPopUp);
  };
  const onClickOnlist = (listItem) => {
    setCountyInputValue(listItem)
    setOpenPopUp(!openPopUp);
  }
  const handleModalSearch = (value) => {
    const cloneCountryCodes = [...CountryCodeConstants]
    const filteredData = cloneCountryCodes.filter((country) => {
      const { name, code } = country;
      return name.toLowerCase().includes(value.toLowerCase()) || code.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCountryCode(filteredData);
    setSearchTerm(value)
  }

  return (
    <Grid>
      <Container sx={LoginStyles.container}>
        <Grid style={{ width: '100%', marginTop: '3rem', marginBottom: '3rem' }}>
          <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h4" sx={LoginStyles.loginContainer.labelText}>
              LOGIN
            </Typography>
          </Grid>
          <Grid sx={LoginStyles.inputsContainer}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              style={{ borderRadius: '25px', marginBottom: '2rem', width: '312px'   }}
            >
              <Typography variant="body1" sx={LoginStyles.labels}>COUNTRY</Typography>
              <Box
                component="div"
                sx={LoginStyles.country.container}
                onClick={handleCloseOpenModal}
              >
                <Box
                  component="span"
                  sx={LoginStyles.country.text}
                >
                  {countyInputValue.name}
                </Box>
                <IconButton edge="end" >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box >
            </Grid >
          </Grid >
          <Grid sx={LoginStyles.inputsContainer} style={{ marginTop: '0rem' }}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              style={{ borderRadius: '25px', marginBottom: '0rem' , width: '312px' }}
            >
              <Typography variant="body1" sx={LoginStyles.labels}>MOBILE NUMBER</Typography>
              <TextField
                fullWidth
                variant="outlined"
                sx={{
                  "& fieldset": { border: 'none' },
                }}
                value={loginInputs.number}
                onChange={handleMobileNumberChange}
                error={error}
                helperText={error ? "Please enter a valid mobile number" : null}
                InputProps={{
                  style: {
                    borderRadius: '25px',
                    backgroundColor: '#f2f2f2',
                    paddingLeft: '16px',
                    paddingRight:'10px',
                    minWidth: '154px',
                    height: '49px',
                    textAlign: 'left',
                    fontWeight: 'normal',
                    fontSize: '19px',
                    fontFamily: 'Open Sans, sans-serif',
                    // lineHeight: '22px',
                    letterSpacing: '0px',
                    color: '#0F0F0F',
                    opacity: 1
                  },
                  startAdornment: <Box sx={{ marginRight: '7px', color: '0F0F0F' }}>{countyInputValue.dial_code}</Box>,
                }}
              />
            </Grid>
          </Grid>

          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '2rem',
            }}
          >
            <Grid item xs={12}>
              <Box sx={LoginStyles.buttonContent.container}>
                <Button variant="contained" sx={LoginStyles.buttonContent.button} onClick={handleContinueClick}>
                  CONTINUE
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid style={{ width: '50%', margin: 'auto', marginTop: '5rem' }}>
            <Typography variant="h5" sx={LoginStyles.bottomText}>
              NOT REGISTERED?
            </Typography>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <Box sx={LoginStyles.buttonContent.container}  >
                <Button variant="contained" sx={LoginStyles.buttonContent.button} onClick={() => navigate(routePaths.registration)} >
                  REGISTER
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid >
      </Container >
      {openPopUp && <ModalPopUp
        onClickModal={handleCloseOpenModal}
        type={'country'}
        onClickOnlist={onClickOnlist}
        openDialog={openPopUp}
        topHeading={'SELECT YOUR COUNTRY'}
        listDataType={"name"}
        handleOnchangeSearch={handleModalSearch}
        searchPlaceHolder={"Search country or area code"}
        listingDetails={filteredCountryCode.length > 0 ? filteredCountryCode : (!searchTerm && CountryCodeConstants)}
      />
      }
    </Grid >
  );
};

export default Index;