import React, { useEffect, useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Box } from '@mui/material';
import { verificationStyles } from './style';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import TimerComponent from '../../../components/TimerComponent';
import { reSendOtpVerification} from '../../../redux/auth/action'; // Import the new API action
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LOGIN_BIZZ_URL, VERIFY_APP_URL, VERIFY_BIZZ_APP_URL, VERIFY_BIZZ_URL } from '../../../constants/endUrl/login';
import { DELETE_BIZZ_URL, DELETE_URL } from '../../../constants/endUrl/deleteAccount';

const Index = () => {
  const location = useLocation(); // Hook to get the location object
  const { formData } = location.state || {}; // Destructure formData from location state
  const allAuthDetails = useSelector(state => state.AllLoginDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const isValueValid = value.length === 4; // OTP length set to 4
  const [error, setError] = useState(false);

  // Log formData in useEffect to display it
  useEffect(() => {
    if (formData) {
      console.log('Form Data:', formData); // You can display this data as needed
    }
  }, [formData]);

  const formatDesignRequestBody = () => {
    const { country, countryDialCode, adminContactNo } = allAuthDetails.loginInput;
    const isUserLoggedIn = isEmptyObject(allAuthDetails.loginInput);
    const { registrationRequestBody } = allAuthDetails;
    let cleanedNumber = registrationRequestBody.mobileNo
      ? registrationRequestBody.mobileNo.replace(/^(\+?\d{1,})(\s)?/, "")
      : null;
    const requestBody = {
      adminContactNo: !isUserLoggedIn ? adminContactNo : cleanedNumber,
      otp: value
    };
    return requestBody;
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*$/.test(inputValue)) {
      setError(true);
    } else {
      setError(false);
      const newValue = inputValue.slice(0, 4); // Restrict input to 4 digits
      setValue(newValue.replace(/\D/g, ''));
    }
  };

  const handleResendOtp = (isResend) => {
    const { adminContactNo } = formatDesignRequestBody();
    const payload = { adminContactNo };
    if (isResend) {
      if(formData) {
        if(formData.dropdownValue === 'customer') {
          axios.post(`${process.env.REACT_APP_API_URL}/${LOGIN_BIZZ_URL}`,{
            
              mobileNo: formData.mobileNumber,
              country: formData.countryName,
              countryDialCode:formData.countryCode,
            
          })
            .then((res) => {
              if (res.status === 200) {
                console.log(res)
              }
            })
            .catch((error) => console.error("Error deleting customer account:", error));
        }
        else {
          axios.post(`${process.env.REACT_APP_API_URL}/${VERIFY_BIZZ_URL}`,{
            
            mobileNo: formData.mobileNumber,
            country: formData.countryName,
            countryDialCode:formData.countryCode,
        })
          .then((res) => {
            if (res.status === 200) {
              console.log(res)
            }
          })
          .catch((error) => console.error("Error deleting customer account:", error));
        }
      }
    }
  };

  const handleDeleteRequest = (res) => {
    console.log()
    if(res.data.body.token) {
      if(formData.dropdownValue === "customer") {
        axios.delete(`${process.env.REACT_APP_API_URL}/${DELETE_URL}/${res.data.body.userId}`)
        .then((res) => {
          if (res.status === 200) {
            navigate('/success');
          }
        })
        .catch((error) => console.error("Error deleting customer account:", error));
      }
      else if(formData.dropdownValue === "supplier") {
        axios.delete(`${process.env.REACT_APP_API_URL}/${DELETE_BIZZ_URL}/${res.data.body.userId}`)
        .then((res) => {
          if (res.status === 200) {
            navigate('/success');
          }
        })
        .catch((error) => console.error("Error deleting customer account:", error));
      }
      
    }
  }

  const handleVerification = () => {
    // Dispatch OTP verification and handle response

    if(formData) {
      if(formData.dropdownValue === 'customer') {
        axios.post(`${process.env.REACT_APP_API_URL}/${VERIFY_APP_URL}`,{
          
            mobileNo: formData.mobileNumber,
            country: formData.countryName,
            countryDialCode:formData.countryCode,
            OTP: value
          
        })
          .then((res) => {
            console.log(res)
            if (res.data.head.success === true) {
              handleDeleteRequest(res)
            }
            else {
              alert('OTP not verified !')
              setValue('')
            }
          })
          .catch((error) => {alert.error("Error deleting customer account:", error)});
      }
      else {
        axios.post(`${process.env.REACT_APP_API_URL}/${VERIFY_BIZZ_APP_URL}`,{
          
          mobileNo: formData.mobileNumber,
          country: formData.countryName,
          countryDialCode:formData.countryCode,
          OTP: value
      })
        .then((res) => {
          if (res.data.head.success === true) {
            handleDeleteRequest(res)
          }
          else {
            toast.error('OTP not verified !',);
            setValue('')
          }
        })
        .catch((error) => console.error("Error deleting customer account:", error));
      }
    }


       
      }
    

  return (
    <Grid>
      <Container sx={verificationStyles.container}>
        <Grid style={{ width: '100%', marginTop: '3rem' }}>
          <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h5" sx={{ ...verificationStyles.verificationContainer.labelText, margin: '1rem' }}>
              VERIFICATION
            </Typography>
          </Grid>
          <Grid style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Typography sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="body1">
              We have sent you Verification code to
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Open Sans, sans-serif' }}>
              {allAuthDetails?.loginInput?.mobileNo
                ? `${allAuthDetails.loginInput.countryDialCode} ${allAuthDetails?.loginInput?.mobileNo}`
                : 'No data found.'}
            </Typography>
          </Grid>

          {/* Here you can display some of the formData */}
          {/* {formData && (
            <Grid style={{ textAlign: 'center', marginBottom: '20px' }}>
              <Typography variant="body1" sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                {`Form Data received: ${JSON.stringify(formData)}`}
              </Typography>
            </Grid>
          )} */}

          <Grid sx={verificationStyles.inputsContainer}>
            <Grid item xs={12} sm={8} md={4} style={{ borderRadius: '25px', marginBottom: '0rem', width: '312px' }}>
              <Typography variant="body1" align="center" style={{ marginTop: '2rem', color: '#949494', fontFamily: 'Open Sans, sans-serif' }}>
                ENTER VERIFICATION CODE
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                sx={{ "& fieldset": { border: 'none' } }}
                type="text"
                value={value}
                error={error}
                helperText={error ? "Please enter only numbers" : null}
                InputProps={{
                  style: {
                    borderRadius: '10px',
                    backgroundColor: '#f2f2f2',
                    marginTop: '1rem',
                    textAlign: 'center',
                    height: '47px',
                    fontFamily: 'Open Sans, sans-serif'
                  },
                  inputProps: { style: { textAlign: 'center' } }
                }}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', minHeight: '47px' }}>
            <Grid item xs={12}>
              <TimerComponent time={60} handleClick={handleResendOtp} />
              <Box 
              sx={verificationStyles.buttonContent.container}
              >
                <Button
                
                  variant="contained"
                  disabled={!isValueValid}
                  onClick={handleVerification}
                  sx={{ ...verificationStyles.buttonContent.button, marginTop: '24px' }}
                >
                  CONTINUE
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Index;
