// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/dummyimages.jpeg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/d-wedding.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  background-color: #f5f5f5;
}

/* .container{
    width: 1080px !important;
    max-width: 1080px !important;
} */
.navbar {
  border-bottom: 1px solid #f2f2f2;
}

.hy {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border-radius: 10px;
  opacity: 1;
}

.card {
  height: 156px;
}

.event-location {
  display: flex;
  justify-content: center;
}

.Search-img {
  position: absolute;
  margin-top: 15px;
  margin-left: 15px;
}

.form-control-search {
  background: #f2f2f2;
  border: 1px solid #f4f4f4;
  padding: 12px;
  border-radius: 21px;
  /* min-width: 180vh !important; */
  padding-left: 45px;
  height: 47px;
}

.button {
  border: none;
  border-radius: 21px;
  cursor: pointer;
}

.wimbley {
  background: #f2f2f2 !important;
  height: 80px;
  border-radius: 11px;
}

#customRange1 {
  color: yellow;
}

.hz {
  height: 150px !important;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border-radius: 10px;
  opacity: 1;
}

.hzy {
  min-height: 150px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/common.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAGA;;;GAAA;AAIA;EACI,gCAAA;AAAJ;;AAGA;EACI,yDAAA;EACA,mBAAA;EACA,UAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;;AAGA;EACI,aAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,iCAAA;EACA,kBAAA;EACA,YAAA;AAAJ;;AAIA;EACI,YAAA;EACA,mBAAA;EACA,eAAA;AADJ;;AAIA;EACI,8BAAA;EACA,YAAA;EACA,mBAAA;AADJ;;AAIA;EACI,aAAA;AADJ;;AAIA;EACI,wBAAA;EACA,yDAAA;EACA,mBAAA;EACA,UAAA;AADJ;;AAGA;EACI,iBAAA;EACA,yDAAA;AAAJ","sourcesContent":[".body {\n    background-color: #f5f5f5;\n    // font-family: 'Inter', sans-serif;\n}\n\n/* .container{\n    width: 1080px !important;\n    max-width: 1080px !important;\n} */\n.navbar {\n    border-bottom: 1px solid #f2f2f2;\n}\n\n.hy {\n    background-image: url('../images/dummyimages.jpeg');\n    border-radius: 10px;\n    opacity: 1;\n}\n\n.card {\n    height: 156px;\n}\n\n.event-location {\n    display: flex;\n    justify-content: center;\n}\n\n.Search-img {\n    position: absolute;\n    margin-top: 15px;\n    margin-left: 15px;\n}\n\n.form-control-search {\n    background: #f2f2f2;\n    border: 1px solid #f4f4f4;\n    padding: 12px;\n    border-radius: 21px;\n    /* min-width: 180vh !important; */\n    padding-left: 45px;\n    height: 47px;\n\n}\n\n.button {\n    border: none;\n    border-radius: 21px;\n    cursor: pointer;\n}\n\n.wimbley {\n    background: #f2f2f2 !important;\n    height: 80px;\n    border-radius: 11px;\n}\n\n#customRange1 {\n    color: yellow;\n}\n\n.hz {\n    height: 150px !important;\n    background-image: url('../images/dummyimages.jpeg ');\n    border-radius: 10px;\n    opacity: 1;\n}\n.hzy{\n    min-height: 150px;\n    background-image: url('../images/d-wedding.png');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
