import { LOGIN_URL, VERIFY_URL } from "../../constants/endUrl/login"

const LoginAuth = {
    // login: () => 'app/users/login/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    login: () => `${process.env.REACT_APP_API_URL}/${LOGIN_URL}`,
    verifyOtp: () => `${process.env.REACT_APP_API_URL}/${VERIFY_URL}`,
    reSendOtp: () => `${process.env.REACT_APP_API_URL}/${LOGIN_URL}`,
    register: () => 'app/users/register/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    // verifyOtp: () => 'app/users/verify-otp/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    reSendOtp: () => 'app/users/resend-otp/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    logout: () => 'logout',
    changePassword: () => 'change-password',
    getProfile: () => 'profile',
    resetPassword: () => 'reset-password',
}
export default LoginAuth