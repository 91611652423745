import Login from './Login'
import Registration from './Registration'
import Verification from './Verification'
import Verify from './Verify'
const Screens = {
    Login,
    Registration,
    Verification,
    Verify
}
export default Screens