// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pac-container {
  border-radius: 21px;
  padding-left: 9px;
  padding-right: 9px;
}
.pac-container.pac-logo::after {
  display: none;
  content: none;
}
.pac-container .pac-item {
  padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/screens/App/PackagesServicesScreens/EventLocation/styles.scss"],"names":[],"mappings":"AACA;EACI,mBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AACI;EACI,aAAA;EACA,aAAA;AACR;AACI;EACI,YAAA;AACR","sourcesContent":["\n.pac-container {\n    border-radius: 21px;\n    padding-left: 9px;\n    padding-right: 9px;\n    &.pac-logo::after {\n        display: none;\n        content: none;\n      }\n    .pac-item{\n        padding: 3px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
