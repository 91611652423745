import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useNavigate } from 'react-router';
import { BottomBarConstants } from '../../constants/BottomBarConstants'

const Index = () => {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const handleOnChangeTabs = (event, newValue) => {
        setValue(newValue);
        if (BottomBarConstants[newValue].path) {
            navigate(BottomBarConstants[newValue].path);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                position: 'fixed',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: 0,
            }}
        >
            <BottomNavigation showLabels sx={{   backgroundColor:'#F2F2F2'}} value={value} onChange={handleOnChangeTabs}>
                {BottomBarConstants.map((item, index) => (
                    <BottomNavigationAction
                        sx={{
                            padding:'0px 10px'
                        }}
                        key={index}
                        label={item.label}
                        icon={item.icon}
                    />
                ))}
            </BottomNavigation>
        </Box>
    );
};

export default Index;
